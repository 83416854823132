import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersEditOrderProductRecalcCostsRequestSchema } from './request';
import { OrdersEditOrderProductRecalcCostsResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersEditOrderProductRecalcCostsRequestSchema extends ContractData {
*     orderId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersEditOrderProductRecalcCostsResponseSchema extends ContractData {
* }
*
* ```
*/
export class OrdersEditOrderProductRecalcCostsRouteRpc extends BaseRouteRpc {
  static method = 'orders-edit-order-product-recalc-costs';

  static request: OrdersEditOrderProductRecalcCostsRequestSchema;
  static response: OrdersEditOrderProductRecalcCostsResponseSchema;

  static role = RoleType.admin;
}
