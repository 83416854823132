import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersShipStationStoreListStoresToConnectRequestSchema } from './request';
import { OrdersShipStationStoreListStoresToConnectResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersShipStationStoreListStoresToConnectRequestSchema extends ContractData {
*     key:    string;
*     secret: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersShipStationStoreListStoresToConnectResponseSchema extends ContractData {
*     oldStores: OldStore[];
*     stores:    Store[];
* }
*
* export interface OldStore {
*     companyName:         string;
*     id:                  number;
*     marketplaceName:     string;
*     name:                string;
*     ownedByAnotherUser?: boolean;
* }
*
* export interface Store {
*     companyName:         string;
*     id:                  number;
*     marketplaceName:     string;
*     name:                string;
*     ownedByAnotherUser?: boolean;
* }
*
* ```
*/
export class OrdersShipStationStoreListStoresToConnectRouteRpc extends BaseRouteRpc {
  static method = 'orders-ship-station-store-list-stores-to-connect';

  static request: OrdersShipStationStoreListStoresToConnectRequestSchema;
  static response: OrdersShipStationStoreListStoresToConnectResponseSchema;

  static role = RoleType.unverified;
}
