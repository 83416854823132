import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersEtsyGetEtsyConnectLinkRequestSchema } from './request';
import { OrdersEtsyGetEtsyConnectLinkResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersEtsyGetEtsyConnectLinkRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersEtsyGetEtsyConnectLinkResponseSchema extends ContractData {
*     link:      string;
*     stateHash: string;
* }
*
* ```
*/
export class OrdersEtsyGetEtsyConnectLinkRouteRpc extends BaseRouteRpc {
  static method = 'orders-etsy-get-etsy-connect-link';

  static request: OrdersEtsyGetEtsyConnectLinkRequestSchema;
  static response: OrdersEtsyGetEtsyConnectLinkResponseSchema;

  static role = RoleType.unverified;
}
