import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsPublishProductDisableRequestSchema } from './request';
import { ProductsPublishProductDisableResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsPublishProductDisableRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsPublishProductDisableResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsPublishProductDisableRouteRpc extends BaseRouteRpc {
  static method = 'products-publish-product-disable';

  static request: ProductsPublishProductDisableRequestSchema;
  static response: ProductsPublishProductDisableResponseSchema;

  static role = RoleType.unverified;
}
