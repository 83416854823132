import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindMyForDetailViewRequestSchema } from './request';
import { OrdersAllowedStoresFindMyForDetailViewResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindMyForDetailViewRequestSchema extends ContractData {
*     storeId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindMyForDetailViewResponseSchema extends ContractData {
*     store: Store;
* }
*
* export interface Store {
*     id:                 number;
*     marketplace?:       Marketplace;
*     source:             Source;
*     status:             StoreStatus;
*     storeId:            number;
*     storeName:          string;
*     userId:             string;
*     hiddenCredentials:  HiddenCredentials;
*     lastSuccessfulSync: LastSuccessfulSync;
*     lastSync:           LastSync;
*     ordersTotal:        number;
*     paidOrdersTotal:    number;
* }
*
* export interface HiddenCredentials {
*     apiKey:    string;
*     apiSecret: string;
* }
*
* export interface LastSuccessfulSync {
*     createdAt: Date;
* }
*
* export interface LastSync {
*     createdAt:    Date;
*     errorReason?: "unauthorized";
*     status:       LastSyncStatus;
* }
*
* export type LastSyncStatus = "success" | "fail";
*
* export type Marketplace = "etsy" | "shipstation" | "amazon" | "ebay" | "shopify" | "walmart";
*
* export type Source = "etsy" | "shipstation" | "manual" | "shipstation-root";
*
* export type StoreStatus = "active" | "suspended";
*
* ```
*/
export class OrdersAllowedStoresFindMyForDetailViewRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-my-for-detail-view';

  static request: OrdersAllowedStoresFindMyForDetailViewRequestSchema;
  static response: OrdersAllowedStoresFindMyForDetailViewResponseSchema;

  static role = RoleType.unverified;
}
