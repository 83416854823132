import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindMyRequestSchema } from './request';
import { OrdersAllowedStoresFindMyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindMyRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindMyResponseSchema extends ContractData {
*     stores: Store[];
* }
*
* export interface Store {
*     id:              number;
*     marketplace?:    Marketplace;
*     source:          Source;
*     status:          StoreStatus;
*     storeId:         number;
*     storeName:       string;
*     userId:          string;
*     ordersTotal:     number;
*     paidOrdersTotal: number;
*     sync:            Sync;
* }
*
* export type Marketplace = "etsy" | "shipstation" | "amazon" | "ebay" | "shopify" | "walmart";
*
* export type Source = "etsy" | "shipstation" | "manual" | "shipstation-root";
*
* export type StoreStatus = "active" | "suspended";
*
* export interface Sync {
*     createdAt:    Date;
*     errorReason?: "unauthorized";
*     status:       SyncStatus;
* }
*
* export type SyncStatus = "success" | "fail";
*
* ```
*/
export class OrdersAllowedStoresFindMyRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-my';

  static request: OrdersAllowedStoresFindMyRequestSchema;
  static response: OrdersAllowedStoresFindMyResponseSchema;

  static role = RoleType.unverified;
}
