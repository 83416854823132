import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersStoreGetConnectUrlRequestSchema } from './request';
import { OrdersStoreGetConnectUrlResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersStoreGetConnectUrlRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersStoreGetConnectUrlResponseSchema extends ContractData {
*     url: string;
* }
*
* ```
*/
export class OrdersStoreGetConnectUrlRouteRpc extends BaseRouteRpc {
  static method = 'orders-store-get-connect-url';

  static request: OrdersStoreGetConnectUrlRequestSchema;
  static response: OrdersStoreGetConnectUrlResponseSchema;

  static role = RoleType.unverified;
}
