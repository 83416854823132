import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsPublishProductGetAllRequestSchema } from './request';
import { ProductsPublishProductGetAllResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsPublishProductGetAllRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsPublishProductGetAllResponseSchema extends ContractData {
*     products: Product[];
* }
*
* export interface Product {
*     active:               boolean;
*     description:          string;
*     externalStoreId:      string;
*     id:                   number;
*     personalization:      boolean;
*     personalizationHint?: string;
*     pictures:             string[];
*     productId:            number;
*     tags:                 string[];
*     title:                string;
*     variants:             Variant[];
* }
*
* export interface Variant {
*     id:                 number;
*     price:              string;
*     publishedProductId: number;
*     sku:                string;
* }
*
* ```
*/
export class ProductsPublishProductGetAllRouteRpc extends BaseRouteRpc {
  static method = 'products-publish-product-get-all';

  static request: ProductsPublishProductGetAllRequestSchema;
  static response: ProductsPublishProductGetAllResponseSchema;

  static role = RoleType.unverified;
}
