import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsPublishProductDeleteRequestSchema } from './request';
import { ProductsPublishProductDeleteResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsPublishProductDeleteRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsPublishProductDeleteResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsPublishProductDeleteRouteRpc extends BaseRouteRpc {
  static method = 'products-publish-product-delete';

  static request: ProductsPublishProductDeleteRequestSchema;
  static response: ProductsPublishProductDeleteResponseSchema;

  static role = RoleType.unverified;
}
