import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindMyForFilterRequestSchema } from './request';
import { OrdersAllowedStoresFindMyForFilterResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindMyForFilterRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindMyForFilterResponseSchema extends ContractData {
*     stores: Store[];
* }
*
* export interface Store {
*     id:           number;
*     marketplace?: Marketplace;
*     source:       Source;
*     status:       Status;
*     storeId:      number;
*     storeName:    string;
*     userId:       string;
*     userName?:    string;
* }
*
* export type Marketplace = "etsy" | "shipstation" | "amazon" | "ebay" | "shopify" | "walmart";
*
* export type Source = "etsy" | "shipstation" | "manual" | "shipstation-root";
*
* export type Status = "active" | "suspended";
*
* ```
*/
export class OrdersAllowedStoresFindMyForFilterRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-my-for-filter';

  static request: OrdersAllowedStoresFindMyForFilterRequestSchema;
  static response: OrdersAllowedStoresFindMyForFilterResponseSchema;

  static role = RoleType.unverified;
}
