import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersLoaderLoadByOrderNumbersRequestSchema } from './request';
import { OrdersLoaderLoadByOrderNumbersResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersLoaderLoadByOrderNumbersRequestSchema extends ContractData {
*     orderNumbers: string[];
*     storeId:      number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersLoaderLoadByOrderNumbersResponseSchema extends ContractData {
*     report: Report[];
* }
*
* export interface Report {
*     orderNumber: string;
*     reason?:     string;
*     status:      Status;
* }
*
* export type Status = "success" | "failed" | "ignored";
*
* ```
*/
export class OrdersLoaderLoadByOrderNumbersRouteRpc extends BaseRouteRpc {
  static method = 'orders-loader-load-by-order-numbers';

  static request: OrdersLoaderLoadByOrderNumbersRequestSchema;
  static response: OrdersLoaderLoadByOrderNumbersResponseSchema;

  static role = RoleType.admin;
}
