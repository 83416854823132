import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersReloadStoreReloadRequestSchema } from './request';
import { OrdersReloadStoreReloadResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersReloadStoreReloadRequestSchema extends ContractData {
*     storeIds: number[];
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersReloadStoreReloadResponseSchema extends ContractData {
*     results: Result[];
* }
*
* export interface Result {
*     itemsUploaded?: number;
*     reason?:        Reason;
*     storeId:        number;
*     success:        boolean;
* }
*
* export type Reason = "unauthorized" | "unknown";
*
* ```
*/
export class OrdersReloadStoreReloadRouteRpc extends BaseRouteRpc {
  static method = 'orders-reload-store-reload';

  static request: OrdersReloadStoreReloadRequestSchema;
  static response: OrdersReloadStoreReloadResponseSchema;

  static role = RoleType.unverified;
}
