import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsPublishProductCreateRequestSchema } from './request';
import { ProductsPublishProductCreateResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsPublishProductCreateRequestSchema extends ContractData {
*     active:              boolean;
*     description:         string;
*     personalization:     boolean;
*     personalizationHint: string;
*     productId:           number;
*     publish:             boolean;
*     storeId:             number;
*     tags:                string[];
*     title:               string;
*     variants:            Variant[];
* }
*
* export interface Variant {
*     id:    number;
*     price: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsPublishProductCreateResponseSchema extends ContractData {
*     product: Product;
* }
*
* export interface Product {
*     active:               boolean;
*     description:          string;
*     externalStoreId:      string;
*     id:                   number;
*     personalization:      boolean;
*     personalizationHint?: string;
*     pictures:             string[];
*     productId:            number;
*     tags:                 string[];
*     title:                string;
*     variants:             Variant[];
* }
*
* export interface Variant {
*     id:                 number;
*     price:              string;
*     publishedProductId: number;
*     sku:                string;
* }
*
* ```
*/
export class ProductsPublishProductCreateRouteRpc extends BaseRouteRpc {
  static method = 'products-publish-product-create';

  static request: ProductsPublishProductCreateRequestSchema;
  static response: ProductsPublishProductCreateResponseSchema;

  static role = RoleType.unverified;
}
