import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersShipStationStoreConnectStoresRequestSchema } from './request';
import { OrdersShipStationStoreConnectStoresResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersShipStationStoreConnectStoresRequestSchema extends ContractData {
*     key:      string;
*     secret:   string;
*     storeIds: number[];
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersShipStationStoreConnectStoresResponseSchema extends ContractData {
* }
*
* ```
*/
export class OrdersShipStationStoreConnectStoresRouteRpc extends BaseRouteRpc {
  static method = 'orders-ship-station-store-connect-stores';

  static request: OrdersShipStationStoreConnectStoresRequestSchema;
  static response: OrdersShipStationStoreConnectStoresResponseSchema;

  static role = RoleType.unverified;
}
