import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindMyForOrdersRequestSchema } from './request';
import { OrdersAllowedStoresFindMyForOrdersResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindMyForOrdersRequestSchema extends ContractData {
*     limit:   number;
*     page:    number;
*     search?: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindMyForOrdersResponseSchema extends ContractData {
*     stores: Store[];
*     total:  number;
* }
*
* export interface Store {
*     id:           number;
*     marketplace?: Marketplace;
*     source:       Source;
*     status:       StoreStatus;
*     storeId:      number;
*     storeName:    string;
*     userId:       string;
*     sync:         Sync;
* }
*
* export type Marketplace = "etsy" | "shipstation" | "amazon" | "ebay" | "shopify" | "walmart";
*
* export type Source = "etsy" | "shipstation" | "manual" | "shipstation-root";
*
* export type StoreStatus = "active" | "suspended";
*
* export interface Sync {
*     createdAt:    Date;
*     errorReason?: "unauthorized";
*     status:       SyncStatus;
* }
*
* export type SyncStatus = "success" | "fail";
*
* ```
*/
export class OrdersAllowedStoresFindMyForOrdersRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-my-for-orders';

  static request: OrdersAllowedStoresFindMyForOrdersRequestSchema;
  static response: OrdersAllowedStoresFindMyForOrdersResponseSchema;

  static role = RoleType.unverified;
}
