import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersShipStationStoreUpdateCredentialsRequestSchema } from './request';
import { OrdersShipStationStoreUpdateCredentialsResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersShipStationStoreUpdateCredentialsRequestSchema extends ContractData {
*     key:     string;
*     secret:  string;
*     storeId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersShipStationStoreUpdateCredentialsResponseSchema extends ContractData {
* }
*
* ```
*/
export class OrdersShipStationStoreUpdateCredentialsRouteRpc extends BaseRouteRpc {
  static method = 'orders-ship-station-store-update-credentials';

  static request: OrdersShipStationStoreUpdateCredentialsRequestSchema;
  static response: OrdersShipStationStoreUpdateCredentialsResponseSchema;

  static role = RoleType.unverified;
}
