import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresResumeRequestSchema } from './request';
import { OrdersAllowedStoresResumeResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresResumeRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresResumeResponseSchema extends ContractData {
*     store: Store;
* }
*
* export interface Store {
*     id:           number;
*     marketplace?: Marketplace;
*     source:       Source;
*     status:       Status;
*     storeId:      number;
*     storeName:    string;
*     userId:       string;
*     userName?:    string;
* }
*
* export type Marketplace = "etsy" | "shipstation" | "amazon" | "ebay" | "shopify" | "walmart";
*
* export type Source = "etsy" | "shipstation" | "manual" | "shipstation-root";
*
* export type Status = "active" | "suspended";
*
* ```
*/
export class OrdersAllowedStoresResumeRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-resume';

  static request: OrdersAllowedStoresResumeRequestSchema;
  static response: OrdersAllowedStoresResumeResponseSchema;

  static role = RoleType.unverified;
}
