import { BaseRouteRpc, RoleType } from '../../../../../core';
import { ProductsPublishProductPublishRequestSchema } from './request';
import { ProductsPublishProductPublishResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface ProductsPublishProductPublishRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface ProductsPublishProductPublishResponseSchema extends ContractData {
* }
*
* ```
*/
export class ProductsPublishProductPublishRouteRpc extends BaseRouteRpc {
  static method = 'products-publish-product-publish';

  static request: ProductsPublishProductPublishRequestSchema;
  static response: ProductsPublishProductPublishResponseSchema;

  static role = RoleType.unverified;
}
