import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersEtsyProcessCallbackRequestSchema } from './request';
import { OrdersEtsyProcessCallbackResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersEtsyProcessCallbackRequestSchema extends ContractData {
*     code:      string;
*     stateHash: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersEtsyProcessCallbackResponseSchema extends ContractData {
*     storeId: number;
* }
*
* ```
*/
export class OrdersEtsyProcessCallbackRouteRpc extends BaseRouteRpc {
  static method = 'orders-etsy-process-callback';

  static request: OrdersEtsyProcessCallbackRequestSchema;
  static response: OrdersEtsyProcessCallbackResponseSchema;

  static role = RoleType.unverified;
}
