import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresSuspendRequestSchema } from './request';
import { OrdersAllowedStoresSuspendResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresSuspendRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresSuspendResponseSchema extends ContractData {
*     store: Store;
* }
*
* export interface Store {
*     id:           number;
*     marketplace?: Marketplace;
*     source:       Source;
*     status:       Status;
*     storeId:      number;
*     storeName:    string;
*     userId:       string;
*     userName?:    string;
* }
*
* export type Marketplace = "etsy" | "shipstation" | "amazon" | "ebay" | "shopify" | "walmart";
*
* export type Source = "etsy" | "shipstation" | "manual" | "shipstation-root";
*
* export type Status = "active" | "suspended";
*
* ```
*/
export class OrdersAllowedStoresSuspendRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-suspend';

  static request: OrdersAllowedStoresSuspendRequestSchema;
  static response: OrdersAllowedStoresSuspendResponseSchema;

  static role = RoleType.unverified;
}
