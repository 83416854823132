import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersEtsyCheckConnectStatusRequestSchema } from './request';
import { OrdersEtsyCheckConnectStatusResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersEtsyCheckConnectStatusRequestSchema extends ContractData {
*     stateHash: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersEtsyCheckConnectStatusResponseSchema extends ContractData {
*     connected: boolean;
* }
*
* ```
*/
export class OrdersEtsyCheckConnectStatusRouteRpc extends BaseRouteRpc {
  static method = 'orders-etsy-check-connect-status';

  static request: OrdersEtsyCheckConnectStatusRequestSchema;
  static response: OrdersEtsyCheckConnectStatusResponseSchema;

  static role = RoleType.unverified;
}
