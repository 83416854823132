import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresDeleteRequestSchema } from './request';
import { OrdersAllowedStoresDeleteResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresDeleteRequestSchema extends ContractData {
*     id: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresDeleteResponseSchema extends ContractData {
* }
*
* ```
*/
export class OrdersAllowedStoresDeleteRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-delete';

  static request: OrdersAllowedStoresDeleteRequestSchema;
  static response: OrdersAllowedStoresDeleteResponseSchema;

  static role = RoleType.unverified;
}
