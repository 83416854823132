import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersShipOrderSyncShipmentRequestSchema } from './request';
import { OrdersShipOrderSyncShipmentResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersShipOrderSyncShipmentRequestSchema extends ContractData {
*     orderId: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersShipOrderSyncShipmentResponseSchema extends ContractData {
* }
*
* ```
*/
export class OrdersShipOrderSyncShipmentRouteRpc extends BaseRouteRpc {
  static method = 'orders-ship-order-sync-shipment';

  static request: OrdersShipOrderSyncShipmentRequestSchema;
  static response: OrdersShipOrderSyncShipmentResponseSchema;

  static role = RoleType.admin;
}
